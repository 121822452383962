import { useEffect } from 'react';

export const useClickOutside = (ref, handler, isActive = true) => {
	useEffect(() => {
		const listener = (e) => {
			if (!ref.current || ref.current.contains(e.target) || !isActive) {
				return;
			}

			handler(e);
		};

		document.addEventListener('click', listener);

		return () => {
			document.removeEventListener('click', listener);
		};
	}, [ref, handler]);
};

export const getUpdatedItems = (products, quantity, cartKey) => {
	// Create an empty array.
	const updatedItems = [];

	// Loop through the product array.
	products.forEach((cartItem) => {
		// If you find the cart key of the product user is trying to update, push the key and new quantity.
		if (cartItem.cartKey === cartKey) {
			updatedItems.push({
				key: cartItem.cartKey,
				quantity: parseInt(quantity),
			});

			// Otherwise just push the existing qty without updating.
		} else {
			updatedItems.push({
				key: cartItem.cartKey,
				quantity: cartItem.quantity,
			});
		}
	});

	// Return the updatedItems array with new quantity.
	return updatedItems;
};

export const checkValidity = (value, rules) => {
	const val = {
		isValid: true,
		message: '',
	};

	console.log('rules', rules);

	if (!rules) {
		return val;
	}

	if (rules.required) {
        val.isValid = value.trim() !== '' && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'Obavezno polje';
        }
    }

    if (rules.isEmail) {
        const pattern =
            /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        val.isValid = pattern.test(value) && val.isValid;
        if (val.message === '' && !val.isValid) {
            val.message = 'Ukucajte pravi email';
        }
    }

    if (rules.minLength) {
        val.isValid = value.length >= rules.minLength && val.isValid;
        if (val.message === '' && !val.isValid) {
            // val.message = 'Lozinka mora sadržati minimum 8 karaktera';
            val.message = 'Netačna lozinka ili email';
        }
    }

	// if (rules.isPassword) {
	//     const pattern = RegExp(
	//         `^(?=.*[0-9~!@#$%^&*]).{${rules.minLength || 8},${
	//             rules.maxLength || 20
	//         }}$`
	//     );
	//     val.isValid = pattern.test(value) && val.isValid;
	//     if (val.message === '' && !val.isValid) {
	//         val.message = 'Lozinka mora sadrzati minimum 8 karaktera';
	//     }
	// }

	return val;
};
