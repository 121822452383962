import styles from './GridFilter.module.scss';

export default function GridFilter({ active, onClick }) {
    return (
        <div className={styles.display}>
            <p>Prikaz</p>
            <div className={styles.dots}>
                <div
                    className={[
                        styles.dot_1,
                        active === '6' ? styles.active_dot : null,
                    ].join(' ')}
                    onClick={() => onClick('6')}
                ></div>
                <div
                    className={[
                        styles.dot_2,
                        active === '4' ? styles.active_dot : null,
                    ].join(' ')}
                    onClick={() => onClick('4')}
                ></div>
                <div
                    className={[
                        styles.dot_3,
                        active === '2' ? styles.active_dot : null,
                    ].join(' ')}
                    onClick={() => onClick('2')}
                ></div>
            </div>
        </div>
    );
}
