import PropTypes from 'prop-types';

import styles from './PrimaryButton.module.scss';

const PrimaryButton = ({
    disabled,
    active,
    clicked,
    children,
    style,
    className,
}) => {
    const btnStyle = [styles.button];

    if (active) {
        btnStyle.push(styles.active);
    }

    if (className) {
        btnStyle.push(className);
    }

    return (
        <button
            disabled={disabled}
            className={btnStyle.join(' ')}
            onClick={clicked}
            style={style}
        >
            {children}
        </button>
    );
};

PrimaryButton.propTypes = {
    disabled: PropTypes.bool,
    clicked: PropTypes.func,
    children: PropTypes.node,
    active: PropTypes.bool,
    style: PropTypes.object,
    className: PropTypes.string,
};

PrimaryButton.defaultProps = {
    disabled: false,
};

export default PrimaryButton;
