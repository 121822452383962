export const loginState = {
    email: {
        elementConfig: {
            name: 'username',
            type: 'email',
        },
        value: '',
        validation: {
            required: true,
            maxLength: 63,
            isEmail: true,
        },
        valid: false,
        touched: false,
        errorMessage: '',
    },
    password: {
        elementConfig: {
            name: 'password',
            type: 'password',
        },
        value: '',
        validation: {
            required: true,
            minLength: 8,
            maxLength: 20,
            isPassword: true,
        },
        valid: false,
        touched: false,
        errorMessage: '',
    },
};

export const registerState = {
    email: {
        elementConfig: {
            name: 'username',
            type: 'email',
        },
        value: '',
        validation: {
            required: true,
            maxLength: 63,
            isEmail: true,
        },
        valid: false,
        touched: false,
        errorMessage: '',
    },
    password: {
        elementConfig: {
            name: 'password',
            type: 'password',
        },
        value: '',
        validation: {
            required: true,
            minLength: 8,
            maxLength: 20,
            isPassword: true,
        },
        valid: false,
        touched: false,
        errorMessage: '',
    },
    firstName: {
        elementConfig: {
            name: 'firstname',
            type: 'text',
        },
        value: '',
        validation: {
            required: true,
        },
        valid: false,
        touched: false,
        errorMessage: '',
    },
    lastName: {
        elementConfig: {
            name: 'lastname',
            type: 'text',
        },
        value: '',
        validation: {
            required: true,
        },
        valid: false,
        touched: false,
        errorMessage: '',
    },
};

export const CONTACT_US = {
    email: {
        elementConfig: {
            name: 'username',
            type: 'email',
        },
        value: '',
        validation: {
            required: true,
            isEmail: true,
        },
        valid: false,
        touched: false,
        errorMessage: '',
    },
    firstName: {
        elementConfig: {
            name: 'firstname',
            type: 'text',
        },
        value: '',
        valid: false,
        touched: false,
    },
    lastName: {
        elementConfig: {
            name: 'lastname',
            type: 'text',
        },
        value: '',
        valid: false,
        touched: false,
    },
    subject: {
        elementConfig: {
            name: 'subject',
            type: 'text',
        },
        value: '',
        validation: {
            required: true,
        },
        valid: false,
        touched: false,
        errorMessage: '',
    },
    message: {
        elementConfig: {
            name: 'message',
            type: 'text',
        },
        value: '',
        validation: {
            required: true,
        },
        valid: false,
        touched: false,
        errorMessage: '',
    },
};

export const PASSWORD_CHANGE = {
    newPassword: {
        elementConfig: {
            name: 'newPassword',
            type: 'password',
        },
        value: '',
        validation: {
            required: true,
            minLength: 8,
            maxLength: 20,
            isPassword: true,
        },
        valid: false,
        touched: false,
        errorMessage: '',
    },
    confirmPassword: {
        elementConfig: {
            name: 'confirmPassword',
            type: 'password',
        },
        value: '',
        validation: {
            required: true,
            minLength: 8,
            maxLength: 20,
            isPassword: true,
        },
        valid: false,
        touched: false,
        errorMessage: '',
    },
};

// @TODO styling-optimization - move to theme provider
export const CONTAINER_CONTENT_SIZE = 1174;