import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Header from 'components/Header/Header';
import TransparentButton from 'ui/TransparentButton/TransparentButton';

import styles from './Layout.module.scss';
import SideDrawer from 'ui/SideDrawer/SideDrawer';
import Newsletter from 'components/Newsleter/Newsletter';
import Footer from 'components/Footer/Footer';

const Layout = ({ children, header, footer, user, cart }) => {
    const [showSideDrawer, setShowSideDrawer] = useState(false);
    const [displayCookieBanner, setDisplayCookieBanner] = useState(
        typeof localStorage._ovs_cookies_sb === 'undefined'
    );

    const drawerOpenClickHandler = () => {
        setShowSideDrawer(true);
    };

    const drawerCloseClickHandler = () => {
        setShowSideDrawer(false);
    };

    useEffect(() => {
        const body = document.querySelector('body');
        if (showSideDrawer) {
            body.classList.add('no_scroll');
        } else {
            body.classList.remove('no_scroll');
        }
    }, [showSideDrawer]);

    const setCookies = (flag) => {
        window.cookie_consent_statistics = flag;
        localStorage._ovs_c_acc = flag;
        localStorage._ovs_cookies_sb = false;
        setDisplayCookieBanner(false);
    };

    return (
        <>
            <Header
                drawerClickHandler={drawerOpenClickHandler}
                navList={header}
                user={user}
                cart={cart}
            />
            <SideDrawer
                show={showSideDrawer}
                drawerClickHandler={drawerCloseClickHandler}
                menu={header}
            />
            <main className={styles.main}>{children}</main>
            {/* <div
                className={[
                    styles.cookie_banner_wrapper,
                    displayCookieBanner
                        ? styles.show_banner
                        : styles.hide_banner,
                ].join(' ')}
            >
                <p>
                    OVS internet stranica koristi kolačiće za pružanje boljeg
                    korisničkog iskustva. Korišćenjem ovog sajta se slažete sa
                    korišćenjem kolačića (cookies). Više o tome možete pronaći
                    na našim{' '}
                    <Link to="/uslovi-koriscenja">uslovima korišćenja.</Link>
                </p>
                <div className={styles.buttons}>
                    <TransparentButton
                        className={styles.button}
                        clicked={() => setCookies(false)}
                    >
                        Ne, hvala
                    </TransparentButton>
                    <TransparentButton
                        className={styles.button}
                        clicked={() => setCookies(true)}
                    >
                        Da, prihvatam
                    </TransparentButton>
                </div>
            </div> */}
            <Newsletter />
            <Footer navList={footer} />
        </>
    );
};

export default Layout;
