const SliderDots = ({ slider, currentSlide, styles }) => {
    return (
        <div className={styles.dots}>
            {[...Array(slider.details().size).keys()].map((idx) => {
                return (
                    <button
                        key={idx}
                        onClick={() => {
                            slider.moveToSlideRelative(idx);
                        }}
                        aria-label={`go to slide ${idx + 1}`}
                        className={styles.dot_wrapper}
                    >
                        <div
                            className={[
                                styles.dot,
                                currentSlide === idx ? styles.active : null,
                            ].join(' ')}
                        ></div>
                    </button>
                );
            })}
        </div>
    );
};

export default SliderDots;
