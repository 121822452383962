import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Group, Image, Stack, Text, createStyles } from '@mantine/core';
import no_image from 'assets/images/no_image.png';

const useStyles = createStyles((theme) => ({
    imageRoot: {
        overflow: 'hidden',
    },
    image: {
        transform: 'none',
        transition: '250ms cubic-bezier(0.215, 0.61, 0.355, 1)',
        '&:hover': {
            transform: 'scale(1.3)',
        },
    },
    group: {
        gap: 0,
        marginTop: theme.spacing.xs,
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
            marginTop: theme.spacing.md,
        },
        [`@media (max-width: 1454px)`]: {
            flexDirection: 'column'
        },
    },
    name: {
        color: theme.colors.dark[9],
        textTransform: 'uppercase',
        fontSize: theme.fontSizes.xs,
        [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
            fontSize: theme.fontSizes.sm,
        },
    },
    price: {
        fontWeight: 700,
        fontSize: theme.fontSizes.sm,
        color: theme.colors.dark[9],
        [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
            fontSize: theme.fontSizes.md,
        },
    },
    color: {
        fontSize: theme.fontSizes.xs,
        color: theme.colors.dark[1],
        [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
            fontSize: theme.fontSizes.sm,
        },
    },
    sale: {
        textDecoration: 'line-through',
        fontSize: theme.fontSizes.xs,
        color: theme.colors.dark[1],
        [`@media (min-width: ${theme.breakpoints.lg}px)`]: {
            fontSize: theme.fontSizes.sm,
            textAlign: 'right',
        },
        [`@media (max-width: 1454px)`]: {
            textAlign: 'left',
        },
    },
}));

export default function SingleProduct({ data, type }) {
    const { classes } = useStyles();
    const productData =
        type === 'home' ? data?.highlightProductSelection : data;

    const normalizedData = normalizeData(productData);

    return (
        <Card p={0} radius={0}>
            <Card.Section>
                <Link to={normalizedData.linkTo}>
                    <Image
                        classNames={{
                            root: classes.imageRoot,
                            image: classes.image,
                        }}
                        src={normalizedData.imgSrc}
                        alt={normalizedData.altText}
                    />
                </Link>
            </Card.Section>
            <Group className={classes.group}>
                <Stack spacing={0}>
                    <Text className={classes.name}>
                        <Link to={normalizedData.linkTo}>
                            {normalizedData.name}
                        </Link>
                    </Text>
                    <Text className={classes.color}>
                        {normalizedData.colorVariantsCount}&nbsp;
                        {normalizedData.colorVariantsCount === 1
                            ? 'boja'
                            : 'boje'}
                    </Text>
                </Stack>
                <Stack spacing={0}>
                    <Text className={classes.price}>
                        {normalizedData.price}
                    </Text>
                    {normalizedData.isOnSale && (
                        <Text className={classes.sale}>
                            {normalizedData.regularPrice}
                        </Text>
                    )}
                </Stack>
            </Group>
        </Card>
    );
}

function normalizeData(data) {
    return {
        linkTo: `/proizvodi/${data?.slug}`,
        isOnSale: data?.onSale,
        name: data?.name,
        imgSrc: data?.image?.mediaItemUrl || no_image,
        imgAlt: data?.image?.altText || '',
        price: data?.price?.replace('&nbsp;', '') || '0,00rsd',
        regularPrice: data?.regularPrice?.replace('&nbsp;', '') || '',
        colorVariantsCount: data?.paBojas?.nodes.length,
    };
}
