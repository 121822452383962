export const theme = {
    breakpoints: {
        xs: 350,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        '2xl': 1536,
    },
};
