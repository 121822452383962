import React from 'react';

import HighlightCategoryItem from 'ui/HighlightCategoryItem/HighlightCategoryItem';

import styles from './HighlightSection.module.scss';

const HighlightSection = ({ data }) => {
    return (
        <div className={styles.highlight_section}>
            <div className={styles.highlight_section_items}>
                {data?.map((item) => {
                    return (
                        <HighlightCategoryItem
                            data={item}
                            imgData={item.highlightCategoryImage}
                            key={item.highlightButtonText}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default HighlightSection;
