import { gql } from '@apollo/client';

export const PRODUCT_FILTERS = gql`
    query PRODUCT_FILTERS($category: String) {
        products(where: { category: $category }) {
            nodes {
                paBojas {
                    nodes {
                        id
                        name
                    }
                }
                paVelicinas {
                    nodes {
                        id
                        name
                    }
                }
            }
        }
    }
`;

// Attributes of the product will help to identify the product color.
// query MyQuery($slug: ID!) {
//     product(id: $slug, idType: SLUG) {
//       ... on VariableProduct {
//         id
//         name
//         variations(where: {}) {
//           edges {
//             node {
//               id
//               name
//               slug
//               attributes {
//                 edges {
//                   node {
//                     id
//                     label
//                     name
//                     value
//                     attributeId
//                   }
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
  

export const PRODUCT_FILTER = gql`
    query PRODUCT_FILTER(
        $bojaFilter: [String]
        $velicinaFilter: [String]
        $slug: String
        $minPrice: Float
        $maxPrice: Float
        $operatorBoja: TaxonomyOperatorEnum
        $operatorVelicina: TaxonomyOperatorEnum
        $first: Int
        $after: String
        $query: String
    ) {
        products(
            where: {
                taxonomyFilter: {
                    filters: [
                        {
                            taxonomy: PABOJA
                            terms: $bojaFilter
                            operator: $operatorBoja
                        }
                        {
                            taxonomy: PAVELICINA
                            terms: $velicinaFilter
                            operator: $operatorVelicina
                        }
                    ]
                    relation: AND
                }
                category: $slug
                minPrice: $minPrice
                maxPrice: $maxPrice
                search: $query
            }
            first: $first
            after: $after
        ) {
            edges {
                node {
                    name
                    slug
                    ... on VariableProduct {
                        id
                        name
                        price
                        image {
                            id
                            altText
                            mediaItemUrl
                        }
                        paBojas {
                            nodes {
                                id
                                name
                            }
                        }
                        paVelicinas {
                            nodes {
                                id
                                name
                            }
                        }
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
            }
        }
    }
`;

export const PRODUCTS_BY_CATEGORY_SLUG_QUERY = gql`
    query PRODUCT_BY_CATEGORY_SLUG($slug: ID!) {
        products: productCategory(id: $slug, idType: SLUG) {
            id
            name
            slug
            parent {
                node {
                    name
                    slug
                    children {
                        nodes {
                            name
                            slug
                        }
                    }
                }
            }
            count
        }
    }
`;
