import { useState, useEffect, useRef } from 'react';
import { useKeenSlider } from 'keen-slider/react';

import BannerImage from './BannerImage';
import BannerVideo from './BannerVideo';
import SliderDots from './SliderDots';
import PrimaryButton from 'ui/PrimaryButton/PrimaryButton';

import { sliderSettings } from 'utils/sliderSettings';

import styles from './Slider.module.scss';
import { useHistory } from 'react-router-dom';

const SLIDE_INTERVAL = 10000;

const HomeBanner = ({ items }) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const timer = useRef();
    const [sliderRef, slider] = useKeenSlider(sliderSettings(setCurrentSlide));
    const history = useHistory();

    useEffect(() => {
        timer.current = setInterval(() => {
            slider?.next();
        }, SLIDE_INTERVAL);
        return () => {
            clearInterval(timer.current);
        };
    }, [slider, currentSlide]);

    return (
        <div className={styles.slider_wrapper}>
            <section className={styles.banner_wrapper} aria-label="banner">
                <div
                    ref={sliderRef}
                    className={['keen-slider', styles.background].join(' ')}
                >
                    {items?.map((item) => {
                        return (
                            <div
                                key={`${item?.title}__${item?.subtitle}`}
                                className={[
                                    'keen-slider__slide',
                                    styles.slider,
                                ].join(' ')}
                            >
                                <div className={styles.hero_banner}>
                                    <div className={styles.hero_banner_second}>
                                        {item?.slideType?.toLowerCase() ===
                                        'image' ? (
                                            <BannerImage
                                                data={item.homePageSliderImage}
                                                styles={styles}
                                            />
                                        ) : (
                                            <BannerVideo
                                                data={item.video}
                                                styles={styles}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className={styles.inner_wrapper}>
                                    <h2>{item.title}</h2>
                                    <p>{item.subtitle}</p>
                                    <PrimaryButton
                                        clicked={() =>
                                            history.push(`${item.buttonLink}`)
                                        }
                                    >
                                        {item.buttonText}
                                    </PrimaryButton>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {slider?.details().size > 1 && (
                    <SliderDots
                        slider={slider}
                        currentSlide={currentSlide}
                        styles={styles}
                    />
                )}
            </section>
            {/* <div className={styles.background}></div> */}
        </div>
    );
};

export default HomeBanner;
