import { gql } from '@apollo/client';

const GET_HEADER_AND_FOOTER = gql`
    query headerAndFooter($userId: ID!) {
        header: menuItems(where: { location: HEADER_MENU }, first: 1000) {
            nodes {
                key: id
                parentId
                title: label
                path
            }
        }
        footer: menuItems(where: { location: FOOTER_MENU }, first: 1000) {
            nodes {
                key: id
                parentId
                title: label
                path
            }
        }
        user(id: $userId, idType: DATABASE_ID) {
            wishList {
                name
            }
        }
        cart {
            contents {
                nodes {
                    product {
                        node {
                            id
                        }
                    }
                }
            }
        }
    }
`;

export default GET_HEADER_AND_FOOTER;
