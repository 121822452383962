import styles from './PillSwitch.module.scss';

const PillSwitch = ({ data, activeTab, setActiveTab }) => {
    return (
        <div className={styles.tab_button_container}>
            <div className={styles.tabs} role="tablist">
                {data.map((el, index) => {
                    return (
                        <div
                            className={styles.tab}
                            aria-selected={activeTab === el.id}
                            key={el.id}
                            onClick={() => setActiveTab(el.id)}
                            role="tab"
                            id={`tab-${index + 1}`}
                        >
                            {el.name}
                        </div>
                    );
                })}
                <span className={styles.glider}></span>
            </div>
        </div>
    );
};

export default PillSwitch;
