export const sliderSettings = (setCurrentSlide) => {
    return {
        initial: 0,
        loop: true,
        duration: 1100,
        controls: false,
        slideChanged(s) {
            setCurrentSlide(s.details().relativeSlide);
        },
    };
};
