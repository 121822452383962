import { Link } from 'react-router-dom';
import { Container, Image, MediaQuery } from '@mantine/core';
import Accordion from 'ui/Accordion/Accordion';

import FacebookImage from 'assets/icons/facebook.svg';
import LogoImage from 'assets/icons/logo.svg';

import styles from './Footer.module.scss';
import { CONTAINER_CONTENT_SIZE } from 'utils/constants';

export default function Footer({ navList }) {
    const facebookLink = (
        <a
            href="https://www.facebook.com/OVSSerbia/"
            target="_blank"
            rel="noreferrer"
        >
            <Image src={FacebookImage} alt="facebook" width={36} />
        </a>
    );
    const logoWrapper = (
        <div className={styles.logo_wrapper}>
            <Image src={LogoImage} alt="ovs brand" width={65} />
        </div>
    );
    return (
        <footer className={styles.footer}>
            <hr className={styles.divider} />

            <MediaQuery
                smallerThan={1023}
                styles={{ display: 'none !important' }}
            >
                <Container size={CONTAINER_CONTENT_SIZE}>
                    <div className={styles.content}>
                        {logoWrapper}

                        <div className={styles.panel}>
                            {navList?.map((panel) => (
                                <div key={panel?.key}>
                                    <h5 className={styles.list_title}>
                                        {panel?.title}
                                    </h5>
                                    <ul className={styles.list}>
                                        {panel?.children?.map((child) => {
                                            return (
                                                <li key={child?.key}>
                                                    <Link to={child?.path}>
                                                        {child?.title}
                                                    </Link>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            ))}
                            {facebookLink}
                        </div>
                    </div>
                </Container>
            </MediaQuery>

            <MediaQuery
                largerThan={1023}
                styles={{ display: 'none !important' }}
            >
                <div className={styles.mobile_content}>
                    {logoWrapper}
                    <Accordion list={navList} />
                    <div className={styles.social_mobile_wrapper}>
                        {facebookLink}
                    </div>
                </div>
            </MediaQuery>
        </footer>
    );
}
