import { Container, Title, Text, Group } from '@mantine/core';

import PrimaryButton from 'ui/PrimaryButton/PrimaryButton';
import { CONTAINER_CONTENT_SIZE } from 'utils/constants';

import styles from './Newsletter.module.scss';

export default function Newsletter() {
    return (
        <section className={styles.section}>
            <Container size={CONTAINER_CONTENT_SIZE}>
                <Group position="apart">
                    <div>
                        <Title order={2} className={styles.title}>
                            Pretplatite se na naŠ newsletter
                        </Title>
                        <Text className={styles.text}>
                            Budite prvi koji će saznati o novinama u radnjama,
                            popustima i drugim pogodnostima.
                        </Text>
                    </div>
                    <form className={styles.form}>
                        <input
                            className={styles.input}
                            type="email"
                            name="email"
                            placeholder="upišite svoj e-mail"
                        />
                        <PrimaryButton className={styles.form_submit_button}>
                            POŠALJI
                        </PrimaryButton>
                    </form>
                </Group>
            </Container>
        </section>
    );
}
