import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router';
import { useLazyQuery, useQuery } from '@apollo/client';

import {
    PRODUCT_FILTER,
    PRODUCT_FILTERS,
    PRODUCTS_BY_CATEGORY_SLUG_QUERY,
} from 'queries/filter';
import { useClickOutside } from 'utils/utility';

import Breadcrumbs from 'ui/Breadcrumbs/Breadcrumbs';
import SingleProduct from 'ui/SingleProduct/SingleProduct';
import Spinner from 'ui/Spinner/Spinner';

import { ReactComponent as Filter } from 'assets/icons/filter-main.svg';

import styles from './ProductCategory.module.scss';
import { Link } from 'react-router-dom';
import GridFilter from 'ui/GridFilter/GridFilter';
import FilterDrawer from 'ui/FilterDrawer';

import { useToggle } from '@mantine/hooks';
import FilterForm from 'ui/FilterForm';

import {
    useQueryParams,
    withDefault,
    ArrayParam,
    NumberParam,
} from 'use-query-params';

import { uniqBy } from 'lodash';

const LIMIT = 24;

const ProductCategory = ({ match, location, searchQuery, searchResults }) => {
    console.log("search", {searchQuery, searchResults})
    const currentSlug = match?.params?.childCategory;

    console.log('location', {
        location,
        match,
    });

    const [opened, setOpened] = useToggle(false, [false, true]);

    const [query, setQuery] = useQueryParams({
        colors: withDefault(ArrayParam, []),
        sizes: withDefault(ArrayParam, []),
        minPrice: NumberParam,
        maxPrice: NumberParam,
    });

    const { colors, sizes, minPrice, maxPrice } = query;

    const { data: leftSubCategoryData, loading: leftSubCategoryLoading } =
        useQuery(PRODUCTS_BY_CATEGORY_SLUG_QUERY, {
            variables: {
                slug: currentSlug,
            },
        });

    const { fetchMore, data, refetch, loading } = useQuery(PRODUCT_FILTER, {
        variables: {
            bojaFilter: colors,
            velicinaFilter: sizes,
            slug: currentSlug,
            minPrice: Number(minPrice) > 0 ? Number(minPrice) : undefined,
            maxPrice: Number(maxPrice) > 0 ? Number(maxPrice) : undefined,
            operatorBoja: colors.length > 0 ? 'IN' : 'NOT_IN',
            operatorVelicina: sizes.length > 0 ? 'IN' : 'NOT_IN',
            first: LIMIT,
        },
        // fetchPolicy: 'no-cache',
    });

    const { data: filterData } = useQuery(PRODUCT_FILTERS, {
        fetchPolicy: 'no-cache',
        variables: {
            category: currentSlug,
        },
    });

    const handleFilterReset = () => {
        setQuery(
            {
                colors: [],
                sizes: [],
                minPrice: undefined,
                maxPrice: undefined,
            },
            'push'
        );
        refetch({
            bojaFilter: null,
            operatorBoja: 'NOT_IN',
            velicinaFilter: null,
            operatorVelicina: 'NOT_IN',
            slug: currentSlug,
            minPrice: undefined,
            maxPrice: undefined,
        });
        setOpened(false);
    };

    const handleFilterSubmit = (values) => {
        setQuery(
            {
                colors: values.colors,
                sizes: values.sizes,
                minPrice:
                    Number(values.minPrice) > 0
                        ? Number(values.minPrice)
                        : undefined,
                maxPrice:
                    Number(values.maxPrice) > 0
                        ? Number(values.maxPrice)
                        : undefined,
            },
            'push'
        );
        refetch({
            bojaFilter: values.colors,
            velicinaFilter: values.sizes,
            slug: currentSlug,
            operatorBoja: values.colors.length > 0 ? 'IN' : 'NOT_IN',
            operatorVelicina: values.sizes.length > 0 ? 'IN' : 'NOT_IN',
            minPrice:
                Number(values.minPrice) > 0
                    ? Number(values.minPrice)
                    : undefined,
            maxPrice:
                Number(values.maxPrice) > 0
                    ? Number(values.maxPrice)
                    : undefined,
        });
        setOpened(false);
    };

    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const [displayProduct, setDisplayProduct] = useState(
        window.innerWidth <= 415 ? null : '4'
    );
    const [activeFilter, setActiveFilter] = useState(false);
    const ref = useRef();

    const handleDisplayProducts = (display) => {
        setDisplayProduct(display);
    };

    const gridStyle = {
        padding: '1rem 0',
        display: 'grid',
        gridTemplateColumns: `repeat(${displayProduct}, 1fr)`,
        gridTemplateRows: 'auto',
        gridColumnGap: '0.8rem',
        gridRowGap: '2.5rem',
        opacity: activeFilter ? '0.6' : 1,
        pointerEvents: activeFilter ? 'none' : null,
        position: 'relative',
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        refetch();
    }, [currentSlug, location.key]);

    let imageHeight;

    switch (displayProduct) {
        case '6':
            imageHeight = 350;
            break;
        case '4':
            imageHeight = 500;
            break;
        case '2':
            imageHeight = 900;
            break;
        default:
            imageHeight = 240;
            break;
    }

    const openFilter = () => {
        setActiveFilter(false);
    };

    useClickOutside(ref, openFilter, activeFilter);

    const nodes = data?.products?.edges;
    const pageInfo = data?.products?.pageInfo;

    const noResultsAtAll =
        filterData?.products?.nodes?.length === 0 && nodes?.length === 0;

    const filters = getFilterColors(filterData);

    if (loading) {
        return <Spinner />;
    }

    return (
        <div className={styles.product_category_page}>
            <div className={styles.aside}>
                <h3 className={styles.list_title}>
                    {leftSubCategoryData?.products?.parent?.node?.name}
                </h3>
                <ul className={styles.list}>
                    {leftSubCategoryData?.products?.parent?.node?.children?.nodes.map(
                        (item) => (
                            <li
                                style={{
                                    fontWeight:
                                        currentSlug === item.slug ? 700 : 400,
                                }}
                                key={item.slug}
                            >
                                <Link
                                    to={`/kategorija-proizvoda/${match.params.parentCategory}/${item.slug}`}
                                >
                                    {item.name}
                                </Link>
                            </li>
                        )
                    )}
                </ul>
            </div>
            <div className={styles.content}>
                <Breadcrumbs data={breadcrumbs} />
                <div className={styles.filter_and_display}>
                    {!noResultsAtAll && (
                        <div
                            className={styles.filter}
                            onClick={() => {
                                setOpened(true);
                            }}
                        >
                            <Filter />
                            <span>Filteri</span>
                        </div>
                    )}
                    {nodes?.length !== 0 && (
                        <div className={styles.grid_filter}>
                            <GridFilter
                                active={displayProduct}
                                onClick={(size) => handleDisplayProducts(size)}
                            />
                        </div>
                    )}
                </div>
                <div
                    className={styles.product_category_content}
                    style={window.innerWidth <= 415 ? null : gridStyle}
                >
                    {nodes?.map((item) => {
                        return (
                            <SingleProduct
                                key={item.node ? item.node.id : item.id}
                                data={item.node ? item.node : item}
                                style={imageHeight}
                                disableClick={activeFilter}
                            />
                        );
                    })}
                </div>

                {noResultsAtAll && (
                    <p style={{ textAlign: 'center', marginBottom: 40 }}>
                        Nisu pronađeni proizvodi iz odabrane kategorije.
                    </p>
                )}

                {!noResultsAtAll && nodes?.length === 0 && (
                    <p style={{ textAlign: 'center', marginBottom: 40 }}>
                        Nisu pronađeni rezultati za zadate filtere.
                    </p>
                )}

                {nodes?.length !== 0 && (
                    <div className={styles.button_wrapper}>
                        <button
                            className={styles.button}
                            onClick={() =>
                                fetchMore({
                                    variables: {
                                        first: LIMIT,
                                        after: pageInfo.endCursor,
                                    },
                                })
                            }
                            disabled={
                                data?.products?.products?.nodes?.length < 24 ||
                                filterData?.products?.edges?.length < 24 ||
                                nodes?.length < 24
                            }
                        >
                            Ostali Artikli
                        </button>
                    </div>
                )}
                <FilterDrawer
                    opened={opened}
                    onClose={() => {
                        setOpened(false);
                    }}
                >
                    <FilterForm
                        key={location.key}
                        colors={colors}
                        sizes={sizes}
                        minPrice={minPrice}
                        maxPrice={maxPrice}
                        filters={filters}
                        onReset={handleFilterReset}
                        onSubmit={handleFilterSubmit}
                    />
                </FilterDrawer>
                {/* <Sidebar
                    ref={ref}
                    type="filter"
                    active={activeFilter && !searchQuery}
                    onClose={() => setActiveFilter(false)}
                    availableColors={
                        searchResults && searchQuery
                            ? loadedData?.products?.paBoja
                            : data?.products?.paBoja
                    }
                    availableSizes={
                        searchResults && searchQuery
                            ? loadedData?.products?.paVelicina
                            : data?.products?.paVelicina
                    }
                    slug={searchResults && searchQuery ? null : currentSlug}
                    filteredData={(data) => setLoadedData(data)}
                    filterParams={(data) => setFilterParams(data)}
                    onReset={() => setFilterParams(null)}
                    fetchData={fetchData}
                /> */}
            </div>
        </div>
    );
};

/**
 *
 * @TODO In should be added in loader as data query
 * DATA NORMALIZATION
 */
function getFilterColors(data) {
    if (!data) {
        return { colors: [], sizes: [] };
    }

    const colors = data.products.nodes
        .map((item) =>
            item.paBojas.nodes.map((b) => ({
                id: b.id,
                name: b.name,
            }))
        )
        .flat();

    const sizes = data.products.nodes
        .map((item) =>
            item.paVelicinas.nodes.map((v) => ({
                id: v.id,
                name: v.name,
            }))
        )
        .flat();

    const s = uniqBy(sizes, 'id');
    const c = uniqBy(colors, 'id');

    return { colors: c, sizes: s };
}

export default withRouter(ProductCategory);
