import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { MAIN_URLS, ONBOARDING_URLS, PROFILE, FALLBACK } from 'utils/urlList';

import HomePage from 'components/HomePage/HomePage';

const Categories = lazy(() => import('components/Categories/Categories'));
const ProductCategory = lazy(() =>
    import('components/ProductCategory/ProductCategory')
);
const SingleProduct = lazy(() =>
    import('components/SingleProductPage/SingleProductPage')
);
const Cart = lazy(() => import('components/Cart/Cart'));
const Login = lazy(() => import('components/Login/Login'));
const Registration = lazy(() => import('components/Registration/Registration'));
const ForgottenPassword = lazy(() =>
    import('components/ForgottenPassword/ForgottenPassword')
);
const ConfirmPassword = lazy(() =>
    import('components/ForgottenPassword/ConfirmPassword/ConfirmPassword')
);
const Profile = lazy(() => import('components/Profile/Profile'));
const ProfileMobile = lazy(() =>
    import('components/Profile/Navigation/Navigation')
);
const Wishlist = lazy(() => import('components/Wishlist/Wishlist'));
const FourOFour = lazy(() => import('components/404/FourOFour'));
const ContactUs = lazy(() => import('components/ContactUs/ContactUs'));
const Loyalty = lazy(() => import('components/Loyalty/Loyalty'));
const PrivacyPolicy = lazy(() =>
    import('components/PrivacyPolicy/PrivacyPolicy')
);
const FindAStore = lazy(() => import('components/FindAStore/FindAStore'));

const PaymentsPage = lazy(() => import('routes/placanja'));
const ShipmentInformationPage = lazy(() =>
    import('routes/informacije-o-posiljci')
);
const RulesOfPrivacyPage = lazy(() => import('routes/pravila-o-privatnosti'));
const RefundPage = lazy(() => import('routes/povracaji-i-refundacije'));

const SalePage = lazy(() => import('components/SalePage/SalePage'));
const SaleCategoryPage = lazy(() =>
    import('components/SaleCategoryPage/SaleCategoryPage')
);

const RouterWrapper = ({ auth2 }) => {
    const userToken = localStorage.getItem('ovs_at');

    return (
        <Suspense fallback={null}>
            {userToken ? <LoggedIn auth2={auth2} /> : <Guest auth2={auth2} />}
        </Suspense>
    );
};

const LoggedIn = ({ auth2 }) => {
    return (
        <Switch>
            <Route
                exact
                path={MAIN_URLS.SINGLE_PRODUCT}
                component={SingleProduct}
            />
            <Route exact path={MAIN_URLS.CATEGORIES} component={Categories} />
            <Route exact path={MAIN_URLS.HOME} component={HomePage} />
            <Route
                exact
                path={MAIN_URLS.PRODUCT_CATEGORY}
                component={ProductCategory}
            />
            <Route
                exact
                path={MAIN_URLS.MY_CART}
                render={() => <Cart auth2={auth2} />}
            />
            <Route
                exact
                path={ONBOARDING_URLS.LOGIN}
                render={() => <Login auth2={auth2} />}
            />
            <Route
                exact
                path={ONBOARDING_URLS.SIGNUP}
                component={Registration}
            />
            <Route
                exact
                path={ONBOARDING_URLS.FORGOT_PASSWORD}
                component={ForgottenPassword}
            />
            <Route
                exact
                path={ONBOARDING_URLS.CONFIRM_PASSWORD}
                component={ConfirmPassword}
            />
            <Route
                exact
                path={PROFILE.MY_PROFILE_WILDCARD}
                component={Profile}
            />
            <Route exact path={PROFILE.PROFILE} component={ProfileMobile} />
            <Route exact path={MAIN_URLS.WISHLIST} component={Wishlist} />
            <Route exact path={MAIN_URLS.LOYALTY} component={Loyalty} />
            <Route exact path={MAIN_URLS.FIND_A_STORE} component={FindAStore} />

            {/** Footer - Help (POMOĆ) section */}
            <Route
                exact
                path={MAIN_URLS.FOOTER.HELP_SECTION.PAYMENTS}
                component={PaymentsPage}
            />
            <Route
                exact
                path={MAIN_URLS.FOOTER.HELP_SECTION.SHIPMENT_INFORMATION}
                component={ShipmentInformationPage}
            />
            <Route
                exact
                path={MAIN_URLS.FOOTER.HELP_SECTION.REFUND}
                component={RefundPage}
            />
            <Route
                exact
                path={MAIN_URLS.FOOTER.HELP_SECTION.RULES_OF_PRIVACY}
                component={RulesOfPrivacyPage}
            />
            {/** End of Footer - Help (POMOĆ) section */}

            <Route
                exact
                path={MAIN_URLS.FOOTER.HELP_SECTION.CONTACT_US}
                component={ContactUs}
            />
            <Route exact path={MAIN_URLS.SALE} component={SalePage} />
            <Route
                exact
                path={MAIN_URLS.SALE_WILDCARD}
                component={SaleCategoryPage}
            />
            <Route
                exact
                path={MAIN_URLS.PRIVACY_POLICY}
                component={PrivacyPolicy}
            />
            <Route exact path={FALLBACK.FOUR_O_FOUR} component={FourOFour} />
            <Redirect to={'/404'} />
        </Switch>
    );
};

const Guest = ({ auth2 }) => {
    return (
        <Switch>
            <Route
                exact
                path={MAIN_URLS.SINGLE_PRODUCT}
                component={SingleProduct}
            />
            <Route exact path={MAIN_URLS.CATEGORIES} component={Categories} />
            <Route exact path={MAIN_URLS.HOME} component={HomePage} />
            <Route
                exact
                path={MAIN_URLS.PRODUCT_CATEGORY}
                component={ProductCategory}
            />
            <Route
                exact
                path={MAIN_URLS.MY_CART}
                render={() => <Cart auth2={auth2} />}
            />
            <Route
                exact
                path={ONBOARDING_URLS.LOGIN}
                render={() => <Login auth2={auth2} />}
            />
            <Route
                exact
                path={ONBOARDING_URLS.SIGNUP}
                component={Registration}
            />
            <Route
                exact
                path={ONBOARDING_URLS.FORGOT_PASSWORD}
                component={ForgottenPassword}
            />
            <Route
                exact
                path={ONBOARDING_URLS.CONFIRM_PASSWORD}
                component={ConfirmPassword}
            />
            <Route exact path={MAIN_URLS.LOYALTY} component={Loyalty} />
            <Route exact path={MAIN_URLS.FIND_A_STORE} component={FindAStore} />

            {/** Footer - Help (POMOĆ) section */}
            <Route
                exact
                path={MAIN_URLS.FOOTER.HELP_SECTION.PAYMENTS}
                component={PaymentsPage}
            />
            <Route
                exact
                path={MAIN_URLS.FOOTER.HELP_SECTION.SHIPMENT_INFORMATION}
                component={ShipmentInformationPage}
            />
            <Route
                exact
                path={MAIN_URLS.FOOTER.HELP_SECTION.REFUND}
                component={RefundPage}
            />
            <Route
                exact
                path={MAIN_URLS.FOOTER.HELP_SECTION.RULES_OF_PRIVACY}
                component={RulesOfPrivacyPage}
            />
            <Route
                exact
                path={MAIN_URLS.FOOTER.HELP_SECTION.CONTACT_US}
                component={ContactUs}
            />

            <Route exact path={MAIN_URLS.SALE} component={SalePage} />
            <Route
                exact
                path={MAIN_URLS.SALE_WILDCARD}
                component={SaleCategoryPage}
            />
            <Route
                exact
                path={MAIN_URLS.PRIVACY_POLICY}
                component={PrivacyPolicy}
            />
            <Route exact path={FALLBACK.FOUR_O_FOUR} component={FourOFour} />
            <Redirect to={'/404'} />
        </Switch>
    );
};

export default RouterWrapper;
