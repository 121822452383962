import React from 'react';
import { Link } from 'react-router-dom';

import Button from 'ui/PrimaryButton/PrimaryButton';

import no_image from 'assets/images/no_image.png';

import styles from './HomeCategoryItem.module.scss';

const SingleProduct = ({ imageSrc, buttonText, slug, image }) => {
    return (
        <div className={styles.home_category_item}>
            <Link
                to={`/kategorija-proizvoda${slug}`}
                className={styles.link_wrapper}
            >
                <img
                    src={image?.mediaItemUrl || no_image}
                    alt={image?.altText || ''}
                />
                <Button className={styles.button}>{buttonText}</Button>
            </Link>
        </div>
    );
};

export default SingleProduct;
