import React from 'react';
import { useHistory } from 'react-router-dom';

import PrimaryButton from 'ui/PrimaryButton/PrimaryButton';

import styles from './HomeBannerImage.module.scss';

const HomeBannerImage = ({ data }) => {
    const history = useHistory();

    const handleRedirect = () => {
        history.push(`${data?.bannerButtonLink}`);
    };

    return (
        <section className={styles.home_banner_section}>
            <img
                src={data?.bannerLeftImage?.mediaItemUrl}
                alt={data?.bannerLeftImage?.altText}
            />
            <div className={styles.banner_content}>
                <p>{data?.bannerDescription}</p>
                <PrimaryButton clicked={handleRedirect}>
                    {data?.bannerButtonText}
                </PrimaryButton>
            </div>
            <img
                src={data?.bannerRightImage?.mediaItemUrl}
                alt={data?.bannerRightImage?.altText}
            />
        </section>
    );
};

export default HomeBannerImage;
