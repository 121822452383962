import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import Menu from './Menu/Menu';

import { ReactComponent as Close } from 'assets/icons/close.svg';
import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';

import styles from './SideDrawer.module.scss';

const SideDrawer = ({ show, drawerClickHandler, menu }) => {
    const [active, setActive] = useState([]);

    useEffect(() => {
        setActive([]);
    }, [show]);

    const forward = (key, title) => {
        const actv = [...active, { key, title }];
        setActive(actv);
    };

    const back = () => {
        const actv = [...active];
        actv.pop();
        setActive(actv);
    };

    return (
        <nav
            className={[styles.side_drawer, show ? styles.open : null].join(
                ' '
            )}
        >
            <button
                className={styles.close_button}
                onClick={drawerClickHandler}
                aria-label="close drawer"
            >
                <Close />
            </button>
            <ul className={styles.menu}>
                {menu?.map((item) => (
                    <li key={item.key}>
                        {item.children.length === 0 ? (
                            <Link to={item.path} className={styles.link}>
                                <p onClick={drawerClickHandler}>{item.title}</p>
                            </Link>
                        ) : (
                            <>
                                <button
                                    className={styles.label}
                                    onClick={() =>
                                        forward(item.key, item.title)
                                    }
                                >
                                    {item.title} <Chevron />
                                </button>
                                {menu?.map((item) => (
                                    <Menu
                                        isActive={active?.some(
                                            (el) => el.key === item.key
                                        )}
                                        key={item.key}
                                        index={item.key}
                                        menu={item?.children}
                                        forward={forward}
                                        active={active}
                                        drawerClickHandler={drawerClickHandler}
                                        back={back}
                                    />
                                ))}
                            </>
                        )}
                    </li>
                ))}
            </ul>
        </nav>
    );
};

export default SideDrawer;
