import { Drawer, createStyles, Box } from '@mantine/core';

const useDrawerStyles = createStyles(() => ({
    root: {
        '@media (min-width: 1024px)': {
            top: '146px !important',
        },
    },
    drawer: {
        width: '100%',
        '@media (min-width: 1024px)': {
            width: '50%',
            top: '146px !important',
        },
        '@media (min-width: 1280px)': {
            width: '30%',
        },
    },
    header: {
        backgroundColor: '#001526',
        height: 45,
        paddingLeft: 16,
        paddingRight: 16,
        marginBottom: 0,
    },
    closeButton: {
        color: 'white',
        '&:hover': {
            color: '#001526',
        },
    },
    title: {
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: 18,
        color: 'white',
        fontFamily: 'Barlow',
    },
}));

export default function FilterDrawer({ children, opened, onClose }) {
    const { classes: drawerClasses } = useDrawerStyles();

    return (
        <Drawer
            overlayColor="white"
            classNames={drawerClasses}
            lockScroll
            onClose={onClose}
            opened={opened}
            position="left"
            zIndex={9999}
            title="Filter"
        >
            <Box pt={40} px={14}>
                {children}
            </Box>
        </Drawer>
    );
}
