import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';
import { ReactComponent as Back } from 'assets/icons/back_arrow.svg';
import { ReactComponent as Close } from 'assets/icons/close.svg';

import styles from './Menu.module.scss';
import { Link } from 'react-router-dom';

const Menu = ({
    menu,
    forward,
    drawerClickHandler,
    active,
    isActive,
    back,
    index,
}) => {
    return (
        <ul className={styles.menu} aria-expanded={isActive}>
            <li className={styles.buttons_wrapper}>
                <button
                    className={styles.close_button}
                    onClick={drawerClickHandler}
                    aria-label="close drawer"
                >
                    <Close />
                </button>
                {active?.length !== 0 && (
                    <button onClick={back} className={styles.close_button}>
                        {active?.find((el) => el.key === index)?.title} <Back />
                    </button>
                )}
            </li>
            {menu?.map((item) => (
                <li key={item.key}>
                    {item.children.length === 0 ? (
                        <Link to={item.path} className={styles.link}>
                            <p onClick={drawerClickHandler}>{item.title}</p>
                        </Link>
                    ) : (
                        <>
                            <button
                                className={styles.label}
                                onClick={() => forward(item.key, item.title)}
                            >
                                {item.title} <Chevron />
                            </button>
                            {menu?.map((item) => (
                                <Menu
                                    isActive={active?.some(
                                        (el) => el.key === item.key
                                    )}
                                    key={item.key}
                                    index={item.key}
                                    menu={item?.children}
                                    forward={forward}
                                    active={active}
                                    drawerClickHandler={drawerClickHandler}
                                    back={back}
                                />
                            ))}
                        </>
                    )}
                </li>
            ))}
        </ul>
    );
};

export default Menu;
