import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Breadcrumbs.module.scss';

const Breadcrumbs = ({ data, slug }) => {
    return (
        <div className={styles.breadcrumbs}>
            {data?.map((link, i) => {
                return (
                    <Link to={`/kategorija-proizvoda/${link.slug}`} key={i}>
                        {link.name
                            ?.toLowerCase()
                            .split(' ')
                            .map(
                                (el) => el.charAt(0).toUpperCase() + el.slice(1)
                            )
                            .join(' ')}
                    </Link>
                );
            })}
        </div>
    );
};

export default Breadcrumbs;
