import React from 'react';

import styles from './SaleBanner.module.scss';
import SecondaryButton from 'ui/SecondaryButton/SecondaryButton';
import { useHistory } from 'react-router-dom';

function SaleBanner({ data }) {
    const history = useHistory();

    return (
        <>	
			{/** isSmallDevice are rendered from to md breakpoint */}
            <BannerImage data={data} isSmallDevice />
            <Content data={data} history={history} isSmallDevice />

			{/** without isSmallDevice are rendered from md breakpoint */}
            <BannerImage data={data}>
                <div className={styles.sale_banner_left_overlay} />
                <Content data={data} history={history} />
            </BannerImage>
        </>
    );
}

function BannerImage({ data, children, isSmallDevice = false }) {
    return (
        <div
            className={isSmallDevice ? styles.mobile_banner : styles.banner}
            style={{ backgroundImage: `url(${data?.image?.mediaItemUrl})` }}
        >
            {children}
        </div>
    );
}

function Content({ data, history, isSmallDevice = false }) {
    return (
        <div className={isSmallDevice ? styles.content_small : styles.content}>
            <div className={styles.text}>
                <span>{data?.title}</span>
                <p>{data?.subtitle}</p>
            </div>
            <SecondaryButton
                clicked={() => history.push(`${data?.buttonLink}`)}
                className={styles.sale_button}
            >
                {data?.buttonTitle}
            </SecondaryButton>
        </div>
    );
}

export default SaleBanner;
