import React from 'react';

import styles from './Spinner.module.scss';

const Spinner = () => {
    return (
        <div className={styles.spinner_wrapper}>
            <div className={styles.group}>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    );
};

export default Spinner;
