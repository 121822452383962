import { useState } from 'react';
import { Link } from 'react-router-dom';

import Panel from './Panel/Panel';

import styles from './Accordion.module.scss';

const Accordion = ({ list }) => {
    const [activeTab, setActiveTab] = useState(null);

    const activateTab = (index) => {
        setActiveTab(activeTab === index ? -1 : index);
    };

    return (
        <div className={styles.accordion} role="tablist">
            {list?.map((panel, index) => (
                <Panel
                    key={panel?.key}
                    activeTab={activeTab}
                    index={index}
                    label={panel?.title}
                    activateTab={() => activateTab(index)}
                >
                    {panel?.children?.map((child) => {
                        return (
                            <li key={child?.key}>
                                <Link to={child?.path}>{child?.title}</Link>
                            </li>
                        );
                    })}
                </Panel>
            ))}
        </div>
    );
};

export default Accordion;
