import styles from './DrawerToggleButton.module.scss';

const DrawerToggleButton = ({ click }) => (
    <button className={styles.toggle_button} onClick={click} aria-label="open menu">
        <div className={styles.toggle_button_line} />
        <div className={styles.toggle_button_line} />
        <div className={styles.toggle_button_line} />
    </button>
);

export default DrawerToggleButton;
