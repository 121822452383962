import { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';

import { useClickOutside } from 'utils/utility';

import DrawerToggleButton from 'ui/DrawerToggleButton/DrawerToggleButton';
import Search from './Search/Search';

import { ReactComponent as Profile } from 'assets/icons/profile.svg';
import { ReactComponent as SearchIco } from 'assets/icons/search.svg';
import { ReactComponent as ShoppingCart } from 'assets/icons/shopping_chart.svg';
import { ReactComponent as WishList } from 'assets/icons/wish_list_white.svg';
import { ReactComponent as Logo } from 'assets/icons/logo.svg';
import { ReactComponent as ActiveUser } from 'assets/icons/active-user.svg';
import { ReactComponent as PersonIcon } from 'assets/icons/person.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';

import styles from './Header.module.scss';

const Header = ({ drawerClickHandler, navList, user, cart }) => {
    const [active, setActive] = useState(null);
    const [showMenu, setShowMenu] = useState(false);
    const [openSearch, setOpenSearch] = useState(false);
    const client = useApolloClient();
    const searchRef = useRef();
    const profileRef = useRef();

    const location = useLocation();

    const openProfileMenu = () => {
        setShowMenu(!showMenu);
    };

    const toggleSearch = () => {
        setOpenSearch(!openSearch);
    };

    const logout = () => {
        client.cache.reset();
        localStorage.removeItem('ovs_at');
        localStorage.removeItem('ovs_uid');
        window.location.pathname = '/';
    };

    useEffect(() => {
        if (openSearch) {
            setOpenSearch(false);
        }
    }, [location]);

    useEffect(() => {
        openSearch && searchRef.current.focus();
    }, [openSearch]);

    useClickOutside(profileRef, openProfileMenu);

    return (
        <>
            <Search
                open={openSearch}
                ref={searchRef}
                toggleSearch={toggleSearch}
            />
            <header className={styles.header}>
                <div className={styles.heder_top}>
                    <DrawerToggleButton click={drawerClickHandler} />
                    <Link to="/">
                        <Logo className={styles.logo} />
                    </Link>
                    <div className={styles.nav_wrapper}>
                        <ul className={styles.nav_list}>
                            <li className={styles.search_icon}>
                                <SearchIco
                                    onClick={toggleSearch}
                                    style={{ cursor: 'pointer', width: '1.5rem', height: '1.5rem' }}
                                />
                            </li>
                            <li className={styles.icon}>
                                {localStorage.getItem('ovs_at') ? (
                                    <div
                                        className={styles.profile_wrapper}
                                        onClick={openProfileMenu}
                                    >
                                        <ActiveUser />
                                        {showMenu && (
                                            <div
                                                className={styles.profile_menu}
                                                ref={profileRef}
                                            >
                                                <Link
                                                    to={
                                                        window.innerWidth <= 415
                                                            ? '/moj-profil'
                                                            : '/moj-profil/licni-podaci'
                                                    }
                                                >
                                                    <PersonIcon />
                                                    Moj profil
                                                </Link>
                                                <p onClick={logout}>
                                                    <Logout />
                                                    Odjavi se
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <Link to="/prijavi-se">
                                        <Profile />
                                    </Link>
                                )}
                            </li>
                            {localStorage.getItem('ovs_at') && (
                                <li className={styles.icon}>
                                    {user?.wishList !== null && (
                                        <div className={styles.count}>
                                            {user?.wishList?.length}
                                        </div>
                                    )}
                                    <Link to="/lista-zelja">
                                        <WishList />
                                    </Link>
                                </li>
                            )}
                            <li className={styles.icon}>
                                {cart?.contents?.nodes?.length !== 0 && (
                                    <div className={styles.count}>
                                        {cart?.contents?.nodes?.length}
                                    </div>
                                )}
                                <Link to="/moja-korpa">
                                    <ShoppingCart />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <nav className={styles.heder_bottom}>
                    <ul>
                        {navList?.map((el) => {
                            return (
                                <li
                                    key={el.key}
                                    onMouseEnter={() => setActive(el.key)}
                                >
                                    <Link
                                        to={el.path}
                                        className={styles.title}
                                        onClick={() => setActive(null)}
                                    >
                                        {el.title}
                                    </Link>
                                    {el.children.length !== 0 && (
                                        <div
                                            className={styles.tablist}
                                            aria-hidden={el.key !== active}
                                        >
                                            <ul>
                                                {el.children.map((item) => {
                                                    return (
                                                        <li key={item?.key}>
                                                            {item.children
                                                                .length !==
                                                            0 ? (
                                                                <p
                                                                    className={
                                                                        styles.subtitle
                                                                    }
                                                                >
                                                                    {item.title}
                                                                </p>
                                                            ) : (
                                                                <Link
                                                                    to={
                                                                        item.path
                                                                    }
                                                                    className={
                                                                        styles.title
                                                                    }
                                                                    onClick={() =>
                                                                        setActive(
                                                                            null
                                                                        )
                                                                    }
                                                                >
                                                                    <p>
                                                                        {
                                                                            item.title
                                                                        }
                                                                    </p>
                                                                </Link>
                                                            )}
                                                            <ul>
                                                                {item.children
                                                                    .length !==
                                                                    0 &&
                                                                    item.children.map(
                                                                        (
                                                                            child
                                                                        ) => {
                                                                            return (
                                                                                <li
                                                                                    className={
                                                                                        styles.children
                                                                                    }
                                                                                    key={
                                                                                        child?.key
                                                                                    }
                                                                                    onClick={() =>
                                                                                        setActive(
                                                                                            null
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    <Link
                                                                                        to={
                                                                                            child.path
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            child.title
                                                                                        }
                                                                                    </Link>
                                                                                </li>
                                                                            );
                                                                        }
                                                                    )}
                                                            </ul>
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </nav>
            </header>
        </>
    );
};

export default Header;
