import { forwardRef, useState, useRef, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';

import { PRODUCT_FILTER } from 'queries/filter';

import ProductCategory from 'components/ProductCategory/ProductCategory';

import { ReactComponent as SearchIco } from 'assets/icons/search_dark.svg';
import { ReactComponent as Close } from 'assets/icons/close_dark.svg';

import styles from './Search.module.scss';
import SearchProducts from 'components/SearchProducts/SearchProducts';

const LIMIT = 24;

const Search = forwardRef(({ open, toggleSearch }, ref) => {
    const [inputValue, setInputValue] = useState('');
    const [content, setContent] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('');
    const [seacrhHandler, { data }] = useLazyQuery(PRODUCT_FILTER, {
        onCompleted: (data) =>
            data?.products?.edges?.length !== 0
                ? setContent(true)
                : setNoDataMsg(
                      `Nisu pronađeni rezultati pretrage za "${inputValue}". Pokušajte ponovo ili se vratite na početnu stranicu.`
                  ),
        fetchPolicy: 'no-cache',
        variables: {
            first: LIMIT,
        },
    });
    const closeRef = useRef();

    const location = useLocation();

    const inputChange = (e) => {
        e.persist();
        setInputValue(e.target.value);
        setNoDataMsg('');
        if (!e.target.value) {
            setContent(false);
        }
    };

    const handleSubmit = (e) => {
        if (e.target.value !== '') {
            if (e.keyCode === 13 || e.key === 'Enter') {
                setInputValue(e.target.value);
                seacrhHandler({
                    variables: { query: e.target.value },
                });
            }
        }
    };

    useEffect(() => {
        setContent(false);
        open && toggleSearch();
        setInputValue('');
    }, [location.pathname]);

    useEffect(() => {
        if (content && open) {
            document.body.style.overflow = 'hidden';
        }

        if (!open) {
            setInputValue('');
            setContent(false);
        }

        return () => (document.body.style.overflow = 'unset');
    }, [content, open]);

    useEffect(() => {
        setInputValue('');
    }, [open]);

    return (
        <div className={styles.search_wrapper} ref={closeRef}>
            <div
                className={[
                    styles.search,
                    open ? styles.open : styles.close,
                ].join(' ')}
            >
                <div className={styles.search_content}>
                    <div className={styles.search_box}>
                        <SearchIco
                            className={styles.search_ico}
                            onClick={
                                inputValue !== ''
                                    ? () =>
                                          seacrhHandler({
                                              variables: { query: inputValue },
                                          })
                                    : null
                            }
                        />
                        <input
                            className={styles.input}
                            onChange={inputChange}
                            onKeyDown={handleSubmit}
                            value={!open ? '' : inputValue}
                            // autoFocus
                            ref={ref}
                            noValidate
                            type="text"
                            placeholder="Upišite pojam za pretragu"
                        />
                        <Close
                            className={styles.close_ico}
                            onClick={() => {
                                setInputValue('');
                                setContent(false);
                                setNoDataMsg('');
                            }}
                        />
                    </div>
                </div>
                <p style={{ textAlign: 'center' }}>{noDataMsg}</p>
            </div>
            <div
                className={[
                    styles.results_wrapper,
                    content && open ? styles.open : styles.close,
                ].join(' ')}
                ref={closeRef}
            >
                {inputValue && data && (
                    <SearchProducts
                        searchQuery={inputValue}
                        searchResults={data}
                    />
                )}
            </div>
        </div>
    );
});

export default Search;
