import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { MantineProvider } from '@mantine/core';
import { NotificationsProvider } from '@mantine/notifications';

import { facebookSDK, googleSDK } from './SDK';

import { removeConsoleProd } from 'utils/removeConsoleProd';

import Layout from 'components/Layout/Layout';
import RouterWrapper from 'containers/RouterWrapper';

import 'keen-slider/keen-slider.min.css';

import GET_HEADER_AND_FOOTER from 'queries/get-header-and-footer';
import Spinner from 'ui/Spinner/Spinner';
import { flatListToHierarchical } from 'utils/flatListToHierarchical';
import { theme } from 'theme';

const App = () => {
    const { loading, data } = useQuery(GET_HEADER_AND_FOOTER, {
        variables: {
            userId: localStorage.getItem('ovs_uid')
                ? localStorage.getItem('ovs_uid')
                : '',
        },
    });

    const [auth2, setAuth2] = useState(null);

    useEffect(() => {
        googleSDK(setAuth2);
        facebookSDK('sr_Latn_RS');
        // Print version in the console
        console.log(`App version: ${process.env.REACT_APP_VERSION}`); // eslint-disable-line no-console

        if (process.env.NODE_ENV === 'production') {
            removeConsoleProd();
        }
    }, []);

    if (loading) {
        return <Spinner />;
    }

    return (
        <MantineProvider
            defaultProps={{
                TextInput: {
                    iconWidth: 78,
                },
            }}
            styles={{
                TextInput: {
                    input: {
                        height: 52,
                        borderRadius: 0,
                        borderColor: '#c4c4c4',

                        ':focus-within, :hover': {
                            borderColor: 'black',
                        },
                    },
                    innerInput: {
                        height: 52,
                    },
                    icon: {
                        borderRight: '1px solid #c4c4c4',
                        width: 56,
                    },
                    error: {
                        textAlign: 'right',
                    },
                },
            }}
            theme={theme}
        >
            <NotificationsProvider>
                <Layout
                    header={flatListToHierarchical(data?.header?.nodes)}
                    footer={flatListToHierarchical(data?.footer?.nodes)}
                    user={data?.user}
                    cart={data?.cart}
                >
                    <RouterWrapper auth2={auth2} />
                </Layout>
            </NotificationsProvider>
        </MantineProvider>
    );
};

export default App;
