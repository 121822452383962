import React from 'react';
import { Link } from 'react-router-dom';

import SecondaryButton from 'ui/SecondaryButton/SecondaryButton';

import no_image from 'assets/images/no_image.png';

import styles from './HighlightCategoryItem.module.scss';

const HighlightCategoryItem = ({ data, imgData }) => {
    return (
        <div className={styles.highlight_category_item}>
            <Link
                to={`/kategorija-proizvoda/${data?.highlightCategoryLink?.ancestors?.edges[0]?.node?.slug}/${data?.highlightCategoryLink?.slug}`}
            >
                <img
                    src={imgData.mediaItemUrl || no_image}
                    alt={imgData?.altText || ''}
                />
                <SecondaryButton>{data?.highlightButtonText}</SecondaryButton>
            </Link>
        </div>
    );
};

export default HighlightCategoryItem;
