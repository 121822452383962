import React, { useState } from 'react';

import PillSwitch from './PillSwitch/PillSwitch';
import HomeCategoryItem from 'ui/HomeCategoryItem/HomeCategoryItem';

import styles from './HomeCategories.module.scss';

const HomeCategories = ({ data, pageTitle }) => {
    const [activeTab, setActiveTab] = useState(data[0]?.id);

    return (
        <section className={styles.home_categories_section}>
            <div className={styles.background}></div>
            <div className={styles.tab_component_header}>
                <h2>{pageTitle}</h2>
                <PillSwitch
                    data={data}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
            </div>
            {data.map((panel) => {
                return (
                    <div
                        className={styles.products}
                        role="tabpanel"
                        key={panel.id}
                        aria-hidden={activeTab !== panel.id}
                    >
                        {window.innerWidth <= 415
                            ? panel?.children?.edges
                                  .slice(0, 3)
                                  .map((product) => {
                                      return (
                                          <HomeCategoryItem
                                              buttonText={product?.node?.name}
                                              key={product?.node.id}
                                              slug={`/${panel.slug}/${product.node.slug}`}
                                              image={product?.node?.image}
                                          />
                                      );
                                  })
                            : panel?.children?.edges?.map((product) => {
                                  return (
                                      <HomeCategoryItem
                                          buttonText={product?.node?.name}
                                          key={product?.node?.id}
                                          slug={`/${panel.slug}/${product.node.slug}`}
                                          image={product?.node?.image}
                                      />
                                  );
                              })}
                    </div>
                );
            })}
        </section>
    );
};

export default HomeCategories;
