import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import {
    ApolloProvider,
    ApolloClient,
    InMemoryCache,
    HttpLink,
    ApolloLink,
} from '@apollo/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { relayStylePagination } from '@apollo/client/utilities';

const rootElement = document.getElementById('root');

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

export const middleware = new ApolloLink((operation, forward) => {
    /**
     * If session data exist in local storage, set value as session header.
     */
    const token = localStorage.getItem('ovs_at')
        ? localStorage.getItem('ovs_at')
        : '';
    const session = localStorage.getItem('woo-session');

    // if (session) {
    //     operation.setContext(({ headers = {} }) => ({
    //         headers: {
    //             'woocommerce-session': `Session ${session}`,
    //             // 'Content-Type': 'text/html',
    //         },
    //     }));
    // }

    if (token) {
        operation.setContext(({ headers }) => ({
            headers: {
                ...headers,
                Authorization: 'Bearer ' + token,
                // 'woocommerce-session': `Session ${session}`,
            },
        }));
    } else {
        operation.setContext(({ headers = {} }) => ({
            headers: {
                'woocommerce-session': `Session ${session}`,
            },
        }));
    }

    return forward(operation);
});

export const afterware = new ApolloLink((operation, forward) => {
    return forward(operation).map((response) => {
        /**
         * Check for session header and update session in local storage accordingly.
         */
        const context = operation.getContext();
        const {
            response: { headers },
        } = context;

        const session = headers.get('woocommerce-session');
        if (session) {
            if (localStorage.getItem('woo-session') !== session) {
                localStorage.setItem(
                    'woo-session',
                    headers.get('woocommerce-session')
                );
            }
        }

        return response;
    });
});

export const client = new ApolloClient({
    // link: new HttpLink({
    //     uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
    // }),
    link: middleware.concat(afterware.concat(httpLink)),
    cache: new InMemoryCache({
        dataIdFromObject: (o) => o.id,
        typePolicies: {
            Query: {
                fields: {
                    products: relayStylePagination(),
                },
            },
        },
    }),
    // defaultOptions: {
    //     watchQuery: {
    //         fetchPolicy: 'no-cache',
    //         errorPolicy: 'all',
    //     },
    //     query: {
    //         fetchPolicy: 'no-cache',
    //         errorPolicy: 'all',
    //     },
    //     mutate: {
    //         errorPolicy: 'all',
    //     },
    // },
});

const app = (
    <React.StrictMode>
        <ApolloProvider client={client}>
            <Router>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <App />
                </QueryParamProvider>
            </Router>
        </ApolloProvider>
    </React.StrictMode>
);

if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(app, rootElement);
} else {
    ReactDOM.render(app, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
