import {
    Chips,
    Chip,
    createStyles,
    Stack,
    Title,
    CheckboxGroup,
    Checkbox,
    Group,
    Button,
    ScrollArea,
    NumberInput
} from '@mantine/core';

import * as Yup from 'yup';

import { ReactComponent as UpdateArrow } from 'assets/icons/update_arrows.svg';

import { useForm, yupResolver } from '@mantine/form';

const useChipStyles = createStyles(() => ({
    root: {
        width: 60,
        height: 60,
        display: 'flex',
    },

    label: {
        width: '100%',
        height: '100%',
        borderRadius: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 16,
        fontFamily: 'Barlow',
        fontWeight: 'bolder',
        borderColor: '#001526',
    },
    iconWrapper: {
        display: 'none',
    },
    checked: {
        backgroundColor: '#001526 !important',
        color: 'white',
    },
}));

const useCheckboxStyles = createStyles(() => ({
    icon: {
        display: 'none',
    },
    input: {
        width: 20,
        height: 20,
        borderRadius: 0,
        borderColor: '#001526',
    },
}));

const schema = Yup.object().shape({
    minPrice: Yup.number().nullable(),
    maxPrice: Yup.number().nullable().test({
        message: 'Maksimalna cena mora biti veca od minimalne.',
        test: (value, ctx) => {
            const minPrice = ctx.parent.minPrice;

            if (minPrice > 0 && value > 0) {
                return value > minPrice;
            } else {
                return true;
            }
        },
    }),
});

export default function FilterForm({
    filters,
    onSubmit,
    onReset,
    colors,
    sizes,
    minPrice,
    maxPrice,
}) {
    const { classes } = useChipStyles();
    const { classes: checkboxClasses } = useCheckboxStyles();
    const form = useForm({
        initialValues: {
            colors: colors,
            sizes: sizes,
            minPrice: minPrice ?? 0,
            maxPrice: maxPrice ?? 0,
        },
        schema: yupResolver(schema),
    });

    return (
        <form onSubmit={form.onSubmit(onSubmit)}>
            <Stack spacing="xl">
                <Stack>
                    <Title
                        order={4}
                        style={{
                            fontSize: 20,
                            fontWeight: 600,
                            textTransform: 'uppercase',
                            color: '#001526',
                        }}
                    >
                        Veličina
                    </Title>
                    <Chips
                        multiple
                        spacing={0}
                        classNames={classes}
                        {...form.getInputProps('sizes')}
                    >
                        {filters.sizes?.map((size) => (
                            <Chip key={size.name} value={size.name}>
                                {size.name}
                            </Chip>
                        ))}
                    </Chips>
                </Stack>
                <Stack>
                    <Title
                        order={4}
                        style={{
                            fontSize: 20,
                            fontWeight: 600,
                            textTransform: 'uppercase',
                            color: '#001526',
                        }}
                    >
                        Boje
                    </Title>
                    <ScrollArea
                        scrollbarSize={4}
                        type="always"
                        style={{ height: 200 }}
                    >
                        <CheckboxGroup
                            classNames={checkboxClasses}
                            color="dark"
                            orientation="vertical"
                            {...form.getInputProps('colors')}
                        >
                            {filters.colors.map((color) => (
                                <Checkbox
                                    key={color.id}
                                    value={color.name}
                                    label={color.name}
                                />
                            ))}
                        </CheckboxGroup>
                    </ScrollArea>
                </Stack>
                <Stack>
                    <Title
                        order={4}
                        style={{
                            fontSize: 20,
                            fontWeight: 600,
                            textTransform: 'uppercase',
                            color: '#001526',
                        }}
                    >
                        Cena
                    </Title>
                    <Group align="baseline">
                        <NumberInput
                            hideControls
                            style={{ width: 120 }}
                            styles={{
                                input: {
                                    border: '1px solid #001526',
                                    borderRadius: 0,
                                },
                            }}
                            type="number"
                            label="od"
                            {...form.getInputProps('minPrice')}
                        />
                        <NumberInput
                            hideControls
                            style={{ width: 120 }}
                            styles={{
                                input: {
                                    border: '1px solid #001526',
                                    borderRadius: 0,
                                },
                            }}
                            type="number"
                            label="do"
                            {...form.getInputProps('maxPrice')}
                        />
                    </Group>
                </Stack>
                <Group mt={28}>
                    <Button type="submit" radius={0} color="dark">
                        Pogledaj Rezultate
                    </Button>
                    <Button
                        onClick={onReset}
                        radius={0}
                        color="gray"
                        variant="subtle"
                        rightIcon={<UpdateArrow />}
                    >
                        Resetuj filtere
                    </Button>
                </Group>
            </Stack>
        </form>
    );
}
