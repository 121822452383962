import { gql } from '@apollo/client';

const GET_HOMEPAGE = gql`
    query getHomePage {
        page(id: "/", idType: URI) {
            homeSections {
                title
                slide {
                    buttonLink
                    buttonText
                    subtitle
                    slideType
                    title
                    homePageSliderImage {
                        mediaItemUrl
                        altText
                    }
                    video {
                        id
                        mimeType
                        mediaItemUrl
                        altText
                    }
                    # videoMob {
                    #     id
                    #     mimeType
                    #     mediaItemUrl
                    # }
                }
                highlightCategory {
                    highlightButtonText
                    highlightCategoryLink {
                        slug
                        id
                        ancestors {
                            edges {
                                node {
                                    id
                                    slug
                                }
                            }
                        }
                    }
                    highlightCategoryImage {
                        altText
                        id
                        mediaItemUrl
                    }
                }
                highlightBanner {
                    bannerButtonLink
                    bannerButtonText
                    bannerDescription
                    bannerLeftImage {
                        mediaItemUrl
                        id
                        altText
                    }
                    bannerRightImage {
                        altText
                        id
                        mediaItemUrl
                    }
                }
                categories {
                    slug
                    name
                    id
                    children(first: 6) {
                        edges {
                            node {
                                id
                                name
                                slug
                                image {
                                    id
                                    altText
                                    mediaItemUrl
                                }
                            }
                        }
                    }
                }
                highlightProductTitle
                highlightProduct {
                    highlightProductSelection {
                        ... on VariableProduct {
                            id
                            name
                            price
                            slug
                            paBojas {
                                nodes {
                                    id
                                }
                            }
                            image {
                                altText
                                id
                                mediaItemUrl
                            }
                        }
                    }
                }
            }
            saleBanner {
                slider {
                    buttonLink
                    buttonTitle
                    title
                    subtitle
                    image {
                        altText
                        mediaItemUrl
                    }
                }
            }
            seo {
                title
            }
        }
    }
`;

export default GET_HOMEPAGE;
