export const MAIN_URLS = {
    HOME: '/',
    CATEGORIES: '/kategorija-proizvoda/:category',
    PRODUCT_CATEGORY: '/kategorija-proizvoda/:parentCategory/:childCategory',
    SINGLE_PRODUCT: '/proizvodi/:slug',
    PARENT_CATEGORY: 'kategorija-proizvoda/:slug',
    MY_CART: '/moja-korpa',
    WISHLIST: '/lista-zelja',
    LOYALTY: '/ovs-kartica',
    PRIVACY_POLICY: '/uslovi-koriscenja',
    FIND_A_STORE: '/pronadji-prodavnicu',
    SALE: '/snizenje',
    SALE_WILDCARD: '/snizenje/:category',
    FOOTER: {
        HELP_SECTION: {
            PAYMENTS: '/placanja',
            SHIPMENT_INFORMATION: '/informacije-o-posiljci',
            RULES_OF_PRIVACY: '/pravila-o-privatnosti',
            REFUND: '/povracaji-i-refundacije',
            CONTACT_US: '/kontaktirajte-nas',
        },
    },
};

export const ONBOARDING_URLS = {
    LOGIN: '/prijavi-se',
    SIGNUP: '/registracija',
    FORGOT_PASSWORD: '/zaboravljena-lozinka',
    CONFIRM_PASSWORD: '/nova-lozinka',
};

export const PROFILE = {
    PROFILE: '/moj-profil',
    MY_PROFILE_WILDCARD: '/moj-profil/:type',
    MY_PROFILE_PERSONAL_INFO: '/moj-profil/licni-podaci',
    MY_PROFILE_WISHLIST: '/moj-profil/lista-zelja',
    MY_PROFILE_ORDERS: '/moj-profil/narudzbine',
    MY_PROFILE_ADDRESS: '/moj-profil/adresa',
    MY_PROFILE_PASSWORD_CHANGE: '/moj-profil/promena-lozinke',
};

export const FALLBACK = {
    FOUR_O_FOUR: '/404',
    SERVER_FALLBACK: '/500',
};
