import React from "react";
import { Helmet } from "react-helmet";
const HelmetMetaData = ({ title, image, description }) => {
	return (
		<Helmet>
			<title>{title ? title : process.env.REACT_APP_DOC_TITLE}</title>

			{/* open graph parameters */}

			<meta property="og:title" content={title ? title : process.env.REACT_APP_DOC_TITLE} data-react-helmet="true" />
			<meta property="og:url" content={window.location.href} data-react-helmet="true" />
			<meta property="og:description" content={description ? description : "OVS e-commerce webshop"} data-react-helmet="true" />
			<meta property="og:type" content="website" data-react-helmet="true" />
			<meta property="og:image" content={image ? image : `${process.env.PUBLIC_URL}/default_share_img.jpg`} data-react-helmet="true" />
			<meta property="og:image:secure_url" content={image ? image : `${process.env.PUBLIC_URL}/default_share_img.jpg`} data-react-helmet="true" />

			{/* twitter card parameters */}

			<meta property="twitter:title" content={title ? title : process.env.REACT_APP_DOC_TITLE} data-react-helmet="true" />
			<meta name="twitter:card" content="summary_large_image" data-react-helmet="true" />
			<meta name="twitter:site" content={`@${window.location.href}`} data-react-helmet="true" />
			<meta property="twitter:description" content={description ? description : "OVS e-commerce webshop"} data-react-helmet="true" />
			<meta name="twitter:image" content={image ? image : `${process.env.PUBLIC_URL}/default_share_img.jpg`} data-react-helmet="true" />
		</Helmet>
	);
};

export default HelmetMetaData;
