import { useState, useLayoutEffect, useRef } from 'react';

import { ReactComponent as Chevron } from 'assets/icons/chevron.svg';

import styles from './Panel.module.scss';

const Panel = ({ label, activeTab, index, activateTab, children }) => {
    const [height, setHeight] = useState(0);
    const ref = useRef();

    useLayoutEffect(() => {
        const height = ref.current.scrollHeight;
        setHeight(height);
    }, []);

    const isActive = activeTab === index;

    return (
        <div className={styles.panel} aria-expanded={isActive}>
            <span className={styles.label} role="tab" onClick={activateTab}>
                {label} <Chevron />
            </span>
            <div
                className={styles.inner}
                style={{ height: `${isActive ? height : 0}px` }}
                aria-hidden={!isActive}
                role="tabpanel"
            >
                <ul className={styles.content} ref={ref}>
                    {children}
                </ul>
            </div>
        </div>
    );
};

export default Panel;
