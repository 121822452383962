import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import HomeSlider from './components/slider/Slider';
import HighlightSection from './components/highlightSection/HighlightSection';
import HomeCategories from './components/homeCategories/HomeCategories';
import HomeBannerImage from './components/homeBannerImage/HomeBannerImage';
import ProductsSlider from 'ui/ProductsSlider/ProductsSlider';
import SaleBanner from './components/SaleBanner/SaleBanner';

import GET_HOMEPAGE from 'queries/get-homepage';
import Spinner from 'ui/Spinner/Spinner';

import Helmet from '../Helmet/Helmet';

const HomePage = () => {
    const { data } = useQuery(GET_HOMEPAGE);
    const [spinner, setSpinner] = useState(true);

    console.log('data', spinner);

    useEffect(() => {
        if (data) {
            setSpinner(false);
        }
    }, [data]);

    return (
        <>
            {spinner ? (
                <Spinner />
            ) : (
                <>
                    <Helmet
                        image={data?.page?.image?.mediaItemUrl}
                        title={data?.page?.seo?.title}
                        description={data?.page?.description}
                    />
                    <HomeSlider items={data?.page?.homeSections?.slide} />
                    <HighlightSection
                        data={data?.page?.homeSections?.highlightCategory}
                    />
                    <SaleBanner data={data?.page?.saleBanner?.slider[0]} />
                    <HomeCategories
                        data={data?.page?.homeSections?.categories}
                        pageTitle={data?.page?.homeSections?.title}
                    />
                    <HomeBannerImage
                        data={data?.page?.homeSections?.highlightBanner}
                    />
                    <ProductsSlider
                        data={data?.page?.homeSections?.highlightProduct}
                        title={data?.page?.homeSections?.highlightProductTitle}
                    />
                </>
            )}
        </>
    );
};

export default HomePage;
