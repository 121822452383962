export const flatListToHierarchical = (
    data = [],
    { idKey = 'key', parentKey = 'parentId', childrenKey = 'children' } = {}
) => {
    const tree = [];
    const childrenOf = {};
    const checkPath = (item) => {
        switch (item.path) {
            case '/snizenje-zene/':
                return { ...item, path: '/snizenje/zene/' };
            case '/snizenje-muskarci/':
                return { ...item, path: '/snizenje/muskarci/' };
            case '/snizenje-deca/':
                return { ...item, path: '/snizenje/deca/' };
            default:
                return item;
        }
    };
    data.forEach((item) => {
        const newItem = { ...checkPath(item) };
        const { [idKey]: id, [parentKey]: parentId = 0 } = newItem;
        childrenOf[id] = childrenOf[id] || [];
        newItem[childrenKey] = childrenOf[id];
        parentId
            ? (childrenOf[parentId] = childrenOf[parentId] || []).push(newItem)
            : tree.push(newItem);
    });
    return tree;
};
