import React, { useState, useRef, useEffect } from 'react';

import { useKeenSlider } from 'keen-slider/react';

import SingleProduct from 'ui/SingleProduct/SingleProduct';

import styles from './ProductsSlider.module.scss';

const ProductsSlider = ({ data, title, type }) => {
    const [pause, setPause] = useState(false);
    const timer = useRef();
    const [sliderRef, slider] = useKeenSlider({
        loop: true,
        duration: 1000,
        slidesPerView:
            window.innerWidth <= 415 ? 2 : window.innerWidth <= 1620 ? 3 : 4,
        spacing: 15,
        dragStart: () => {
            setPause(true);
        },
        dragEnd: () => {
            setPause(false);
        },
    });

    useEffect(() => {
        sliderRef.current.addEventListener('mouseover', () => {
            setPause(true);
        });
        sliderRef.current.addEventListener('mouseout', () => {
            setPause(false);
        });
    }, [sliderRef]);

    useEffect(() => {
        timer.current = setInterval(() => {
            if (!pause && slider) {
                slider.next();
            }
        }, 2000);
        return () => {
            clearInterval(timer.current);
        };
    }, [pause, slider]);

    return (
        <section className={styles.products_slider_section}>
            <h2>{title}</h2>
            <div ref={sliderRef} className="keen-slider">
                {data?.map((item, i) => {
                    return (
                        <div
                            key={i}
                            className={[
                                'keen-slider__slide',
                                styles.product_tile_slider,
                            ].join(' ')}
                        >
                            <SingleProduct
                                data={item.node || item}
                                type={type === 'product' ? null : 'home'}
                            />
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default ProductsSlider;
