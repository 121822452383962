import styles from './Slider.module.scss';

const BannerImage = ({ data }) => {
    return (
        <>
            <img
                src={
                    window.innerWidth <= 400
                        ? data?.mediaItemUrl
                        : data?.mediaItemUrl
                }
                alt={data?.altText}
                className={styles.banner_media}
            />
            {/* <img
                src={imageDesktop?.mediaItemUrl}
                alt={imageDesktop?.altText}
                className={styles.banner_media_desktop}
            /> */}
        </>
    );
};

export default BannerImage;
