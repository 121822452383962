const BannerVideo = ({ data, styles }) => {
    return (
        <>
            <video
                muted={true} // defaultMuted
                playsInline
                autoPlay
                loop
                className={styles.banner_media}
            >
                <source src={data?.mediaItemUrl} type={data?.mimeType} />
                <img src={data?.mediaItemUrl} alt={data?.altText} />
            </video>
            {/* <video
                muted={true} // defaultMuted
                playsInline
                autoPlay
                loop
                className={styles.banner_media_desktop}
            >
                <source src={video?.mediaItemUrl} type={video?.mimeType} />
                <img
                    src={imageDesktop?.mediaItemUrl}
                    alt={imageDesktop?.altText}
                />
            </video> */}
            {/* <div className={styles.inner_wrapper}>
                <h2>{title}</h2>
                <p>{subtitle}</p>
                <PrimaryButton>{buttonText}</PrimaryButton>
            </div> */}
        </>
    );
};

export default BannerVideo;
